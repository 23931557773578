import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 17 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M7.883 1.332H6.55c-3.333 0-4.667 1.333-4.667 4.667v4c0 3.333 1.334 4.666 4.667 4.666h4c3.333 0 4.667-1.333 4.667-4.666V8.665"
    }, null, -1),
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "1.5",
      d: "M11.243 2.012 5.99 7.265c-.2.2-.4.593-.44.88l-.287 2.007c-.107.726.407 1.233 1.133 1.133l2.007-.287c.28-.04.673-.24.88-.44l5.253-5.253c.907-.907 1.334-1.96 0-3.293-1.333-1.334-2.386-.907-3.293 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "1.5",
      d: "M10.49 2.766a4.76 4.76 0 0 0 3.293 3.293"
    }, null, -1)
  ])))
}
export default { render: render }